<footer class="footer footer text-white mt-4 py-3">
  <div class="container">
    <div class="pt-4">
      <div class="row">
        <div class="col-6 col-md-2 mb-3">
          <h5>{{ 'app.ui.footer.general' | translate }}</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.general' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.links' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.contact' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <app-language-selector [cookie]="true"></app-language-selector>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-2 mb-3">
          <h5>{{ 'app.ui.footer.members' | translate }}</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2">
              <a routerLink="/members" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.members' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a routerLink="/interclub" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.interclub' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a routerLink="/tournaments" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.tournaments' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.forms' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.regulations' | translate }}
              </a>
            </li>
          </ul>
        </div>

        <div class="col-6 col-md-2 mb-3">
          <h5>{{ 'app.ui.footer.contact-us' | translate }}</h5>
          <p><small>
              Snooker Vlaanderen VZW<br>
              Zuiderlaan 13<br>
              9000 Gent (België)
            </small>
          </p>
        </div>

        <!-- <div class="col-md-5 offset-md-1 mb-3">
          <form [formGroup]="form">
            <h5>{{ 'app.ui.footer.subscribe-to-newsletter' | translate }}</h5>
            <p>{{ 'app.ui.footer.newsletter-monthly-edition' | translate }}</p>
            <div class="d-flex flex-column flex-sm-row w-100 gap-2">
              <label for="newsletter1" class="visually-hidden">{{ 'app.ui.footer.email' | translate }}</label>
              <input id="newsletter1" type="email" class="form-control" formControlName="email" autocomplete="off"
                placeholder="{{ 'app.ui.footer.email' | translate }}">
              <button class="btn btn-secondary" type="button" (click)="onSubscribe()">{{ 'app.ui.button.subscribe' |
                translate }}</button>
            </div>
          </form>
        </div> -->
      </div>

      <div class="d-flex flex-column flex-sm-rowjustify-content-between pt-2">
        <p>{{ 'app.ui.footer.copyright' | translate }} {{ currentYear }}</p>
        <p>{{ 'app.ui.footer.environment-version' | translate: { environment: environment, version: appVersion } }}</p>
        <p><small class="display: inline-block; vertical-align: middle">{{ 'app.ui.footer.developed-by' | translate
            }}&nbsp;<a rel="noopener" href="https://resonate.be" target="_blank">
              <img class="display: inline-block; vertical-align: middle" src="assets/images/resonate_logo.png"
                height="20" alt="resonate_logo"></a></small>
        </p>
        <p>&nbsp;</p>
        <!-- <ul class="list-unstyled d-flex">
        <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24">
              <use xlink:href="#twitter"></use>
            </svg></a></li>
        <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24">
              <use xlink:href="#instagram"></use>
            </svg></a></li>
        <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24">
              <use xlink:href="#facebook"></use>
            </svg></a></li>
      </ul> -->
      </div>
    </div>
  </div>
</footer>